import { Link } from "gatsby"
import React from "react"

import Logo from "../images/logo.svg"

const Header = () => (
  <header className="flex flex-col md:flex-row justify-between items-center text-lg">
    <Link className="mx-8" to="/">
      <img src={Logo} className="logo" alt="The Flying Dev"/>
    </Link>

    <div>
        <Link className="nav-link m-4" to="/">Home</Link>
        <Link className="nav-link m-4" to="/books">Books</Link>
        <Link className="nav-link m-4" to="/digital-garden">Digital Garden</Link>
        <a href="https://teespring.com/stores/landing-in-tech" className="nav-link m-4">Store</a>
        <Link className="nav-link m-4" to="/contact">Contact</Link>
    </div>

  </header>
)

export default Header